<template>
  <div>
    <dish-title 
      title="dtouch.dishOrders.points.title"
      :locale="locale"
    >
      <template #caption>
        <v-btn @click="handleBack">
          <v-icon>
            mdi-arrow-left-thin
          </v-icon>
          {{ $t('dtouch.dishOrders.title', locale) }}
        </v-btn>
      </template>
    </dish-title>
    <v-layout wrap>
      <v-flex xs7>
        <list 
          :onSelectPoint="handleEditPoint"
          :forceUpdate="forceUpdate"
          :locale="locale"
        />
      </v-flex>
      <v-flex xs5 class="main-form">
        <point-form 
          v-if="editedID"
          :editedID="editedID"
          :locale="locale"
          :onClose="handleClose"
          :onSave="handleSave"
          :onDelete="handleDelete"
        />
        <no-edited 
          v-else 
          :locale="locale"
          :onAdd="handleAdd"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiDishOrders from '@/services/apiDishOrders'
import DishTitle from '../Title'
import List from './List'
import PointForm from './PointForm'
import NoEdited from './NoEdited'
export default {
  components: {
    DishTitle,
    List,
    PointForm,
    NoEdited,
  },
  data: () => ({
    editedID: null,
    forceUpdate: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  methods: {
    handleBack () {
      this.$router.push('./dish-orders')
    },
    handleEditPoint (v) {
      this.editedID = v
    },
    handleClose () {
      this.editedID = null
    },
    handleAdd () {
      this.editedID = 'new'
    },
    handleSave (v) {
      apiDishOrders.savePoint(v)
        .then(() => {
          this.forceUpdate = new Date()
          this.handleClose()
        })
    },
    handleDelete (v) {
      apiDishOrders.deletePoint(v)
        .then(() => {
          this.forceUpdate = new Date()
          this.handleClose()
        })
    }
  },
}
</script>
<style scoped>
.main-form {
  background-color: white;
  height: calc(100vh - 128px);
}
</style>

