<template>
  <v-layout wrap>
    <v-flex xs12>
      <v-toolbar
        elevation="0"     
        style="padding-right: 50px;"
      >
        <v-icon large v-if="!isMobile">
          mdi-food-variant
        </v-icon>
        <v-toolbar-title v-if="!isMobile">
          {{ $t(title, locale) }}
        </v-toolbar-title>
        <v-spacer v-if="!isMobile"/>
        <slot 
          name="caption" 
        />
      </v-toolbar>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>
.page-title .icon {
  padding-top: 15px;
}
.page-title h1 {
  margin-left: 20px;
  margin-bottom: 5px;
}
.page-title .v-icon {
  margin-bottom:5px;
  font-size:30px; 
  color: rgba(0, 0, 0, 0.87);
}
.page-title .action {
  padding-top: 10px;
}
</style>

