<template>
  <v-layout wrap style="background-color: #CCCCCC;margin-top:20px;border-radius: 10px; padding: 0 20px;">
    <v-flex xs12>
      <v-checkbox
        v-model="isTemp"
        :value="isTemp"
        :label="$t('availability.isTemp')"
        @change="handleChangeIsModified"
      />
    </v-flex>
    <v-layout wrap v-if="isTemp" align-center>      
      <v-flex xs3 style="text-align: right;padding-right:10px;">
        <b>{{$t('availability.periodicity')}}:</b>
      </v-flex>
      <v-flex xs9>
        <v-radio-group v-model="per" row @change="handleChangeIsModified">
          <v-radio :label="$t('availability.allDays')" :value="0"></v-radio>
          <v-radio :label="$t('availability.oneDay')" :value="1"></v-radio>
          <v-radio :label="$t('availability.recurrence')" :value="2"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs12>
        <v-checkbox
          @change="handleChangeIsModified"
          v-model="isAllTimes"
          :value="isAllTimes"
          :label="$t('availability.isAllTimes')"
        />
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs6>
            <v-dialog
              ref="dialogDateStart"
              v-model="modalDateStart"
              :return-value.sync="dateStart"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateStart"
                  :label="per !== 1 ? $t('availability.dateStart') : $t('availability.date')"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                @change="handleChangeIsModified"
                v-model="dateStart"
                :locale="locale"
                scrollable
                dark
                @input="handleChangeIsModified"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="modalDateStart = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="handleSaveDateStart(dateStart)"
                >
                  {{ $t('common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs6 v-if="!isAllTimes">
            <interval-times
              :label="`${$t('availability.timeStart')}:`"
              v-model="timeStart"
              @input="handleChangeIsModified"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs6>
            <v-checkbox
              v-if="per !== 1"
              v-model="isAllDays"
              :value="isAllDays"
              :label="$t('availability.noEndDay')"
              @change="handleChangeIsModified"
            />
            <v-dialog
              v-if="!isAllDays && per !== 1"
              ref="dialogDateEnd"
              v-model="modalDateEnd"
              :return-value.sync="dateEnd"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateEnd"
                  :label="$t('availability.dateEnd')"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-on="on"
                  @change="handleChangeIsModified"
                />
              </template>
              <v-date-picker
                v-model="dateEnd"
                :locale="locale"
                scrollable
                dark
                @input="handleChangeIsModified"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="modalDateEnd = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="handleSaveDateEnd(dateEnd)"
                >
                  {{ $t('common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs6 v-if="!isAllTimes">
            <interval-times
              :label="`${$t('availability.timeEnd')}___:`"
              v-model="timeEnd"
              @input="handleChangeIsModified"
            />
          </v-flex>
        </v-layout>
      </v-flex>
       <v-flex xs3 style="text-align: right;padding-right:10px;" v-if="per===2">
        <b>{{$t('availability.recurrence')}}:</b>
      </v-flex>
      <v-flex xs9 v-if="per===2">
        <v-radio-group v-model="rec" row @change="handleChangeIsModified">
          <v-radio :label="$t('availability.byWeek')" :value="1"></v-radio>
          <v-radio :label="$t('availability.byMonth')" :value="0"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs12 v-if="per===2 && rec===0">
        <b>{{$t('availability.byMonth')}}:</b>
        <v-container row>
          <v-checkbox
            v-model="dayOfMonth"
            v-for="(day, indexDayOfMonth) in 31"
            :key="`dayOfMonth${indexDayOfMonth}`"
            :label="indexDayOfMonth+1"
            :value="indexDayOfMonth+1"
            style="width:50px;margin-right: 20px;"
            @change="handleChangeIsModified"
            multiple
          />
        </v-container>
      </v-flex>
      <v-flex xs12 v-if="per===2 && rec===1">
        <b>{{$t('availability.byWeek')}}:</b>
        <v-container row>
          <v-checkbox
            v-model="dayOfWeek"
            v-for="(day, indexDayOfweek) in 7"
            :key="`dayOfWeek${indexDayOfweek}`"
            :label="$t(`common.s${indexDayOfweek}`)"
            :value="indexDayOfweek === 6 ? 0 : indexDayOfweek+1"
            style="width:100px;margin-right: 20px;"
            @change="handleChangeIsModified"
            multiple
          />
        </v-container>
      </v-flex>
      <v-flex xs12 v-if="per===2">
        <v-text-field
            :label="$t('availability.alternation')"
            outlined
            v-model="alt"
            @input="handleChangeIsModified"
          />
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
  //import CustomTimePicker from './CustomTimePicker'
  import IntervalTimes from './intervalTimes/Index'
  export default {
    components: {
    //  CustomTimePicker,
      IntervalTimes
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      onChange: {
        type: Function,
        required: true        
      }
    },
    data: () => ({
      isTemp: false,
      per: 0,
      dateStart: new Date().toISOString().split('T')[0],
      dateEnd: new Date().toISOString().split('T')[0],
      timeStart: 120,
      timeEnd: 120,
      isAllTimes: true,
      isAllDays: true,
      rec: 1,
      dayOfWeek: [],
      dayOfMonth: [],
      alt: 1,

      modalDateStart: false,
      modalDateEnd: false
    }),
    computed: {
      locale () {
        return this.$store.state.app.locale
      },
    },
    mounted () {
      this.isTemp = this.data.isTemp
      if (this.data.isTemp) {
        this.per = this.data.per
        this.dateStart = this.data.dateStart.split('T')[0],
        this.dateEnd = this.data.dateEnd.split('T')[0],
        this.timeStart = this.data.timeStart
        this.timeEnd = this.data.timeEnd
        this.isAllTimes = this.data.isAllTimes
        this.isAllDays = this.data.isAllDays
        this.rec = this.data.rec
        this.dayOfWeek = JSON.parse(this.data.dayOfWeek)
        this.dayOfMonth = JSON.parse(this.data.dayOfMonth)
        this.alt = this.data.alt
        this.handleChangeIsModified()
      }
    },
    methods: {
      handleSaveDateEnd(value) {
        this.$refs.dialogDateEnd.save(value)
        this.handleChangeIsModified()
      },
      handleSaveDateStart(value) {
        this.$refs.dialogDateStart.save(value)
        this.handleChangeIsModified()
      },
      handleChangeIsModified () {
        this.onChange({
            isTemp: this.isTemp,
            per: this.per,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            isAllTimes: this.isAllTimes,
            isAllDays: this.isAllDays,
            rec: this.rec,
            dayOfWeek: this.dayOfWeek,
            dayOfMonth: this.dayOfMonth,
            alt: this.alt
          })
      }
    }
  }
</script>
