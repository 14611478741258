<template>
  <v-layout 
    v-if="editedItem"
    wrap
    class="point-form"
  >
    <v-flex 
      xs12
    >
      <v-toolbar
        elevation="0"     
      >
        <v-icon large>mdi-menu</v-icon>
        <v-toolbar-title>
          {{ $t(`common.${isNew ? 'create' : 'edit'}`, locale) }} {{ $t('dtouch.dishOrders.points.point', locale).toLowerCase() }}
        </v-toolbar-title>
      </v-toolbar>
    </v-flex>
    <v-flex xs12 class="form-item">
      <v-text-field
        outlined
        hide-details
        :label="$t('dtouch.dishOrders.points.name', locale)"
        v-model="editedItem.Name"
      />
    </v-flex>
    <v-flex xs12 class="form-item">
      <availability 
        v-if="editedItem.Availability"
        :data="editedItem.Availability" 
        :locale="locale" 
        :onChange="handleChangeAvailability"
      />
    </v-flex>
    <v-flex
      v-if="qrImage"
      xs12
    >
      <!--center>
        <v-img
          :lazy-src="qrImage"
          max-height="344"
          max-width="344"
          :src="qrImage"
          @click="handleGoTo"
        />
      </center-->
      <center>
        <div 
          v-if="svg" 
          v-html="svg" 
          @click="handleGoTo"
        />
      </center>
      <center style="padding-bottom:20px;"><a href="#" @click="download" class="link-download btn btn-primary btn-sm">download QR {{ editedItem.Name }}</a></center>
    </v-flex>
    <v-flex xs12
      class="form-item-action"
    >
      <action-buttons
        :id="editedID"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultData = {
  ID: 'new',
  Name: null,
  Availability: {},
}
import QRCode from 'qrcode'
import ActionButtons from '@/components/ActionButtons'
import apiDishOrders from '@/services/apiDishOrders'
import Availability from './availability/Index'
export default {
  components: {
    ActionButtons,
    Availability,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      default: null
    },
    onClose: {
      type: Function,
      required: true
    },
  },
  data: () => ({
    editedItem: null,
    qrImage: null,
    svg: null,
  }),
  computed: {
    isNew () {
      return this.editedID === 'new'
    },
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.isNew) {
        this.editedItem = JSON.parse(JSON.stringify(defaultData))
        this.editedItem.WorkspaceID = this.workspaceID
      } else this.handleGetItem()
    },
    handleGetItem () {
      this.qrImage = null
      apiDishOrders.getPointByID(this.editedID) 
        .then((response) => {
          if (response.Availability) response.Availability = JSON.parse(response.Availability)
          else response.Availability = {}
          this.editedItem = response
          this.handlePrepareQR()
        })
    },
    handleSave () {
      this.onSave(this.editedItem)
    },
    handlePrepareQR () {
      this.editedItem.url = this.isDevelopment ? `http://${this.editedItem.Domain}.dtouch.local:${window.location.port === '8080' ? '8081' : '8080'}/#/?point=${this.editedItem.ID}` : `https://${this.editedItem.Domain}.dtouch.es/#/?point=${this.editedItem.ID}`
      QRCode.toDataURL(this.editedItem.url, {
        type: 'image/png',
        width: 344,
        color: {
          dark: '#000000',  // Blue dots
          light: '#FFFFFF'
        }
      })
      .then(url => {
        this.qrImage = url
      })
      .catch(err => {
        console.error(err)
      })


      QRCode.toString(this.editedItem.url, {
        type: 'image/svg',
        width: 344,
        color: {
          dark: '#000000',  // Blue dots
          light: '#FFFFFF'
        }
      })
      .then(url => {
        this.svg = url.replace('<svg', '<svg id="dynamicIllustration" ')
      })
      .catch(err => {
        console.error(err)
      })

    },
    handleChangeAvailability (v) {
      this.editedItem.Availability = v && v.isTemp ? v : {}
    },
    handleGoTo () {
      if (!this.editedItem.url) return
      window.open(this.editedItem.url, '_blank').focus()
    },
    download(evt) {
      const svgContent = document.getElementById("dynamicIllustration").outerHTML,
      blob = new Blob([svgContent], {
          type: "image/svg+xml"
        }),
        url = window.URL.createObjectURL(blob),
        link = evt.target

      link.target = "_blank"
      link.download = `qr_${this.editedItem.Name}.svg`
      link.href = url
    },
  },
}
</script>
<style scoped>
.v-icon {
  margin-right: 10px;
}
.point-form {
  background-color: white;
}
.form-item {
  padding: 10px 20px;
}
.form-item-action {
  padding: 10px 10px;
}
</style>

